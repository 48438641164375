<template>
  <div class="flex flex-col min-h-screen">
    <nav class="bg-gray-800 font-bold fixed w-screen flex-none"><!-- fixed -->
      <div class="flex flex-row h-14 items-center">
        <router-link to="/">
          <img alt="Wappen" src="./assets/logo.png" class="h-10 m-1 flex-none">
        </router-link>
        <router-link to="/" class="grow">
          <h1 class="text-xl grow" style="color:#fef119">{{ community }}</h1>
        </router-link>
        <div class="flex-none text-white">{{ weatherName }} {{ temperature }} °C</div>
        <a @click="$router.go(-1)"
          @keydown="1">
          <img alt="Menü" src="./assets/icon_arrow_5.png" class="h-8 m-2 flex-none">
        </a>
        <router-link to="/">
          <img alt="Menü" src="./assets/icon_common_5.png" class="h-10 m-2 flex-none">
        </router-link>
      </div>
    </nav>
    <div class="h-14"></div>
    <div class="flex-auto">
      <router-view class="bg-white"/>
    </div>
    <!-- footer -->
    <div
      class ="bg-gray-800 flex-none">
      <div class = "flex justify-center text-white h-8">
        <router-link to="/impressum"
          class ="self-center">Impressum</router-link><p class="w-4"></p>
        <router-link to="/dsgvo"
          class ="self-center">Datenschutzerklärung</router-link>
      </div>
      <div class = "flex justify-center text-white h-8">
        <!-- p class=""
          @click="$store.state.app.editmode = !$store.state.app.editmode"
          @keydown="1">
          Bearbeiten</p -->
        <p class="w-4"></p>
        <router-link to="/login"
          class ="self-center">Login</router-link>
        <p class="w-4"></p>
        <p class="self-center"> {{ appVersion }}</p>
        <p class="w-4"></p>
        <p v-if="webShareApiSupported"
          @click="shareViaWebShare"
          @keydown="1"
          class="self-center"
          >App teilen</p>
        <a v-else :href="`https://api.whatsapp.com/send?text=${encodeURI(appUrl)}`"
          target="_blank"
          class="self-center">App teilen (WhatsApp)</a>
      </div>
    </div>
  </div>
  <div v-if="updateExists" class="bg-gray-800 text-white flex-none">
    Eine neue Version ist verfügbar
    <button class="btn btn-red" @click="refreshApp">
      Update
    </button>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Cookies from 'js-cookie';

import update from './mixins/update';

export default ({
  mixins: [update],
  setup() {
    const temperature = ref(0);
    const weather = ref({});
    const weatherName = ref('');

    const cookies = ref(undefined);
    const store = useStore();

    const {
      apiUrl, appUrl, community, appVersion,
    } = store.state.app;
    const appId = store.state.app.id;

    const webShareApiSupported = computed({
      get: () => navigator.share,
    });

    function shareViaWebShare() {
      navigator.share({
        title: `Bürgerapp ${community}`,
        text: `Die Bürgerapp für ${community}`,
        url: appUrl,
      });
    }

    function setResults(results) {
      weather.value = results;
      temperature.value = results.temperature;
      weatherName.value = ''; // weather.value.weather[0].main;
    }

    function getWeather() {
      temperature.value = 0;
      fetch(`${apiUrl}/weather`, {
        method: 'GET',
        headers: {
          appid: appId,
          serviceid: 0,
          detailid: 0,
        },
      })
        // eslint-disable-next-line
        .then((res) => { return res.json(); })
        .then(setResults);
    }

    getWeather();

    cookies.value = Cookies.get();
    console.log('cookies:');
    console.log(cookies.value);
    if (cookies.value.loggedIn === 'true') {
      store.state.app.loggedIn = true;
      store.state.user.firstname = cookies.value.firstname;
      store.state.user.lastname = cookies.value.lastname;
      store.state.user.hash = cookies.value.hash;
      store.state.user.rights = cookies.value.rights;
    }

    return {
      temperature,
      weatherName,
      getWeather,
      shareViaWebShare,
      webShareApiSupported,
      community,
      appUrl,
      appVersion,
    };
  },
});

// #f2b621 - dunkelorange
// #ffd416 - hellorange
// #eff823 - gelb
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #f2b621;
}

h1 {
  color: #f2b621;
}

.link {
  color: #f2b621;
}
nav a.router-link-exact-active {
  color: #f2b621;
}
.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-red {
  @apply bg-red-500 text-white;
}
.btn-red:hover {
  @apply bg-red-700;
}

.btn-light-red {
  @apply bg-transparent text-red-500 border border-red-500 px-4 py-2 rounded;
}
.btn-light-red-disabled {
  @apply bg-transparent text-gray-500 border border-gray-500 px-4 py-2 rounded;
}

.button-round {
  @apply border-gray-500 bg-lime-300 rounded-full shadow font-bold;
}
</style>
