<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';

import icon_media_42 from '@/assets/icon_media_42.png';
import icon_media_59 from '@/assets/icon_media_59.png';
import icon_activity_3 from '@/assets/icon_activity_3.png';
import icon_app_118 from '@/assets/icon_app_118.png';
import icon_app_19 from '@/assets/icon_app_19.png';
import icon_device_54 from '@/assets/icon_device_54.png';
import icon_helfer from '@/assets/icon_helfer.png';
import icon_common_3 from '@/assets/icon_common_3.png';
import icon_weather_10 from '@/assets/icon_weather_10.png';
import icon_activity_32 from '@/assets/icon_activity_32.png';

const buttons = ref([
  {
    picture: icon_media_42,
    text: 'Aktuelles',
    link: 'news',
    size: 20,
  },
  {
    picture: icon_weather_10,
    text: 'Wetterstation Webcam',
    link: '/wetterstation',
    size: 20,
  },
  {
    picture: icon_activity_3,
    text: 'Rathaus',
    link: '/rathaus',
    size: 20,
  },
  {
    picture: icon_app_118,
    text: 'Termine',
    link: '/termine',
    size: 20,
  },
  {
    picture: icon_app_19,
    text: 'Vereine',
    link: '/vereine',
    size: 20,
  },
  {
    picture: icon_activity_32,
    picture2: icon_device_54,
    text: 'Gastronomie & Gewerbe',
    link: '/gewerbe',
    size: 10,
  },
  {
    picture: icon_helfer,
    text: 'Kleine Helferlein',
    link: '/helferlein',
    size: 16,
  },
  {
    picture: icon_common_3,
    text: 'Rudolf-Mett-Halle',
    link: 'https://koenigsberg.de/rudolf-mett-halle',
    size: 16,
    extern: true,
  },
]);

const actualTime = ref(0);
const countdown = ref(0);
const Tage = ref(0);
const Stunden = ref(0);
const Minuten = ref(0);
const Sekunden = ref(0);

function updatateTimer() {
  const tag = (24 * 60 * 60 * 1000);
  const stunde = (60 * 60 * 1000);
  let restzeitMs = 0;
  setTimeout(updatateTimer, 1000);
  actualTime.value = Date.now();
  countdown.value = Date.parse('2023-06-30T18:00:00.000+01:00') - Date.now();
  restzeitMs = countdown.value;
  Tage.value = Math.floor(restzeitMs / tag);
  restzeitMs -= Tage.value * tag;
  Stunden.value = Math.floor(restzeitMs / stunde);
  restzeitMs -= Stunden.value * stunde;
  Minuten.value = Math.floor(restzeitMs / (60 * 1000));
  restzeitMs -= Minuten.value * (60 * 1000);
  Sekunden.value = Math.floor(restzeitMs / 1000);
}

updatateTimer();

</script>

<template>
  <div>
    <!-- div class="flex justify-center absolute top-40 items-center w-screen">
      <h2 class="z-10 text-4xl text-red-500 rotate-35">App im<br>Aufbau!</h2>
    </div -->
    <div class="p-2 flex flex-col">
      <div class="sm:px-0 grid">
        <img alt="Schloss" class="rounded-xl justify-self-center" src="../assets/koenigsberg_rathaus.jpg">
      </div>
      <div
        class="
          sm:px-8 sm:pt-4 sm:pb-8
          lg:p-4
          xl:px-8 xl:pt-4 xl:pb-8
          grid grid-cols-3
          sm:grid-cols-3
          gap-2
          my-2"
        v-if="($store.state.app.loggedIn==true
          || countdown < 0)"
      >
        <div v-for="button in buttons"
          :key="button.text"
          class="bg-gray-900 rounded-xl">
          <router-link :to="button.link" v-if="!button.extern">
            <div class="p-2 flex flex-col items-center">
              <div v-if="button.size != 10">
                <img
                  alt="Icon1"
                  :src="button.picture"
                  :class="`${button.size == 20 ?
                  'w-20 h-20 p-2' :
                  'w-16 h-16 p-2'}`"
                />
              </div>
              <div v-if="button.size == 10" class="">
                <img
                  alt="Icon1"
                  :src="button.picture"
                  class="w-8 h-8 relative right-4 bg-lime-60"
                />
                <img
                  alt="Icon2"
                  :src="button.picture2"
                  class="w-8 h-8 relative left-4 bg-lime-40 -top-2"
                />                
              </div>        
              <p class="text-white"
                style="text-align: center"
                :class="`${button.text.length > 10 ?
                '' :
                'text-xl'}`">{{ button.text }}</p>
            </div>
          </router-link>
          <a :href="button.link" target="_blank" v-else>
            <div class="p-2 flex flex-col items-center">
              <img
                alt="Icon1"
                :src="button.picture"
                :class="`${button.size == 20 ?
                'w-20 h-20 p-2' :
                'w-16 h-16 p-2'}`"
              />
              <p class="text-white"
                style="text-align: center"
                :class="`${button.text.length > 10 ?
                '' :
                'text-xl'}`">{{ button.text }}</p>
            </div>
          </a>
        </div>
        <div
          class="bg-blue-800 rounded-xl"
          v-if="$store.state.app.loggedIn==true &&
          $store.state.user.rights>=1">
          <router-link to="/tasks">
            <div class="p-2 flex flex-col items-center">
              <img
                alt="Icon1"
                src="@/assets/icon_app_102.png"
                class="w-20 h-20 p-2"
              />
              <p class="text-xl text-white">Aufgaben</p>
            </div>
          </router-link>
        </div>
        <div
          class="bg-blue-800 rounded-xl"
          v-if="$store.state.app.loggedIn==true &&
          $store.state.user.rights>=1">
          <router-link to="/releasenotes">
            <div class="p-2 flex flex-col items-center">
              <img
                alt="Icon1"
                src="../assets/icon_common_7.png"
                class="w-20 h-20 p-2"
              />
              <p class="text-sm text-white">Release Notes</p>
            </div>
          </router-link>
        </div>
        <div
          class="bg-blue-800 rounded-xl"
          v-if="$store.state.app.loggedIn==true &&
          $store.state.user.rights==1">
          <router-link to="/accounts">
            <div class="p-2 flex flex-col items-center">
              <img
                alt="Icon1"
                src="../assets/icon_app_19.png"
                class="w-16 h-16 p-2"
              />
              <p class="text-sm text-white">Benutzer-<br />verwaltung</p>
            </div>
          </router-link>
        </div>
        <div
          class="bg-blue-800 rounded-xl"
          v-if="$store.state.app.loggedIn==true &&
          $store.state.user.rights==1">
          <router-link to="/statistics">
            <div class="p-2 flex flex-col items-center">
              <img
                alt="Icon1"
                src="../assets/icon_device_18.png"
                class="w-20 h-20 p-2"
              />
              <p class="text-xl text-white">Statistik</p>
            </div>
          </router-link>
        </div>
      </div>
      <div v-else>
        <br />
        <p class="text-3xl font-bold">Bürgerapp Königsberg</p>
        <br />
        <p class="text-2xl" v-if="((Sekunden%5)==4)">Der Countdown läuft.</p>
        <p class="text-2xl" v-if="((Sekunden%5)==3)">Der Countdown läuft..</p>
        <p class="text-2xl" v-if="((Sekunden%5)==2)">Der Countdown läuft...</p>
        <p class="text-2xl" v-if="((Sekunden%5)==1)">Der Countdown läuft....</p>
        <p class="text-2xl" v-if="((Sekunden%5)==0)">Der Countdown läuft.....</p>
        <p class="text-4xl font-bold">
          {{ Tage }} Tag(e)
{{Stunden.toString().padStart(2,0)}}:{{Minuten.toString().padStart(2,0)}}:{{Sekunden.toString().padStart(2,0)}}
        </p>
        <div class="p-4"></div>
        <div class="flex justify-center">
          <router-link to="/vereine?view=newStep1">
            <button class="btn btn-light-red w-64">Verein anmelden</button>
          </router-link>
        </div>
        <div class="p-2"></div>
        <div class="flex justify-center">
          <router-link to="/gewerbe?view=newStep1">
            <button class="btn btn-light-red w-64">Gastronomie oder Gewerbe anmelden</button>
          </router-link>
        </div>
        <div class="p-2"></div>
        <div class="flex justify-center">
          <router-link to="/termine?view=newStep1">
            <button class="btn btn-light-red w-64">Termin einreichen</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
  },
})
export default class HomeView extends Vue {}
</script -->

<style scoped>
h2 {
  font-size: 4em;
  font-family: sans-serif;
  text-shadow: 0 0 2px black;
}
</style>
