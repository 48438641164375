import { createStore } from 'vuex';

export default createStore({
  state: {
    app: {
      editmode: true,
      loggedIn: false,
      // id: '61378dec-d80e-0fb4-6ed3-58384032f2f9', // Rentweinsdorf (id: 1)
      id: '71378cde-d85e-1fb4-6e3d-58384032f29f', // dev-user (id: 2)
      // id: '785253ed-dc19-0fb4-65f3-f2e9d98bdd6c', // Koenigsberg (id: 3)
      apiUrl: 'https://api.buergerinfo.app',
      // apiUrl: 'https://dev-api.buergerinfo.app',
      appUrl: 'https://koenigsberg.buergerinfo.app',
      // appUrl: 'https://dev-app.buergerinfo.app',
      community: 'Königsberg i.Bay.',
      appVersion: '2023.23.2',
      services: [],
    },
    user: {
      firstname: '',
      lastname: '',
      hash: '',
      rights: 0,
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
